$(document).ready(function () {
  $("[role=si-select]").each(function () {
    var stringTemplateResult = $(this).attr("data-template-options");
    var isGroupSelect = $(this).attr("data-is-group-select");
    var stringMinimumLength = $(this).attr("data-minimum-length");
    var getUrl = $(this).attr("data-url-ajax");
    if (
      stringMinimumLength != undefined &&
      getUrl != undefined &&
      stringTemplateResult == undefined
    ) {
      var mappingResult = window[$(this).attr("data-mapping-result")];
      var helperSearch = window[$(this).attr("data-helper-search")];
      var helperNoResults = window[$(this).attr("data-helper-no-results")];
      var dataParamAjax = window[$(this).attr("data-param-ajax")];

      $(this)
        .val($(this).attr("data-selected") ?? "")
        .select2({
          dropdownParent: $(this).parent(),
          placeholder: $(this).attr("data-placeholder") || "",
          ajax: {
            url: getUrl,
            dataType: "json",
            delay: 350,
            data: dataParamAjax ?? 
              function (params) {
                return {
                  query: params.term,
                  page: params.page,
                };
              },
            processResults: mappingResult || undefined,
            cache: true,
          },
          language: {
            inputTooShort: helperSearch,
            noResults: helperNoResults,
          },
          escapeMarkup: function (markup) {
            return markup;
          },
          minimumInputLength: Number(stringMinimumLength) || undefined,
        });
    } else if (
      stringTemplateResult != undefined &&
      isGroupSelect == undefined
    ) {
      var getTemplateResult = window[$(this).attr("data-template-options")];
      $(this)
        .val($(this).attr("data-selected") ?? "")
        .select2({
          dropdownParent: $(this).parent(),
          allowClear:
            !($(this).attr("data-multiple") === "true") ||
            $(this).attr("data-allow-clear") === "true",
          placeholder: $(this).attr("data-placeholder") || "",
          multiple: $(this).attr("data-multiple") === "true",
          templateResult: getTemplateResult || undefined,
        });
    } else if (
      isGroupSelect != undefined &&
      stringTemplateResult == undefined
    ) {
      $(this)
        .val($(this).attr("data-selected") ?? "")
        .select2({
          dropdownParent: $(this).parent(),
          allowClear:
            !($(this).attr("data-multiple") === "true") ||
            $(this).attr("data-allow-clear") === "true",
          placeholder: $(this).attr("data-placeholder") || "",
          multiple: $(this).attr("data-multiple") === "true",
          templateResult:
            isGroupSelect == "true"
              ? function (data, container) {
                  $(container).attr(
                    "style",
                    `padding-left: ${
                      $(data.element).data("level") * 20
                    }px!important`
                  );
                  if ($(data.element).data("is-header")) {
                    $(container).attr(
                      "style",
                      `
                        font-weight: bold!important;
                        padding-left: ${
                          $(data.element).data("level") * 20
                        }px!important
                      `
                    );
                  }
                  return data.text;
                }
              : undefined,
        });
    } else {
      $(this)
        .val($(this).attr("data-selected") ?? "")
        .select2({
          dropdownParent: $(this).parent(),
          allowClear:
            !($(this).attr("data-multiple") === "true") ||
            $(this).attr("data-allow-clear") === "true",
          placeholder: $(this).attr("data-placeholder") || "",
          multiple: $(this).attr("data-multiple") === "true",
        });
    }
  });
});
